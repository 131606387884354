<script>
import UserAvatarInitials from '@/components/general/UserAvatarInitials'
export default {
  name: 'HeaderAvatarMenu',
  components: { UserAvatarInitials },
  props: {
    isAdminCenter: {
      type: Boolean,
      default: false
    },
    activeProfile: {
      type: Boolean,
      default: false
    },
    redirectToHome: {
      type: Function,
      default: () => { }
    },
    allowedOnWorkspaceType: {
      type: Function,
      default: () => { }
    },
    centralActionHandler: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
      actions: ['training.and.development', 'recruitment.and.selection', 'admin.center']
    }
  },
  computed: {
    userWorkspaces () {
      const order = ['aggregator', 'business', 'individual']

      return this.$store.getters.getUserWorkspaces.slice().sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type))
    },
    userInfo () {
      return this.$store.getters.getIndividualWorkspace
    }
  },
  methods: {
    shouldShowMenuOption (action) {
      if (this.selectedWorkspace.type === 'individual' && ['home', 'discovery', 'contents', 'candidacy', 'my.career'].includes(action)) {
        return true
      }
      if (this.isAggregator && ['home', 'discovery', 'contents', 'candidacy'].includes(action)) {
        return true
      }
      if (this.isBusiness && ['home', 'discovery', 'contents', 'internal.positions', 'settings', 'reports'].includes(action)) {
        return true
      }
    },
    manageWorkspace (workspace) {
      this.$store.commit('updateWantedWorkspaceChange', workspace)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
<template>
  <v-list class="avatar-menu--options" :nav="isMobile" dense>
    <template v-if="isMobile">
      <span class="top-right"><v-btn icon @click="close"><v-icon color="#000">mdi-close</v-icon></v-btn></span>
      <div class="avatar-menu--user-info">
        <v-avatar color="indigo" size="56">
          <img v-if="!!workspace.avatar && !(workspace.avatar.includes('/empty'))"
            :src="userInfo.avatar ? changeThumbToSize(userInfo.avatar, 'avatar') : defaultAvatarUrl('profile')"
            alt="Avatar">
          <v-avatar size="56" v-else><user-avatar-initials :user="workspace" :size="56" /></v-avatar>
        </v-avatar>
        <h5>{{ `${userInfo.name} ${userInfo.lastName}` }}</h5>
      </div>
    </template>
    <template v-if="isMobile">
      <div class="h-divider" />
      <h4 class="workspaces-areas--menu_title">{{ $t('workspace.menu.section:title') }}</h4>

      <template v-if="!isAdminCenter && !activeProfile">
        <v-list-item class="workspaces-management--container" @click="redirectToHome">
          <div class="workspace-management-option"><span>{{ $t('main.header:home') }}</span></div>
        </v-list-item>
        <v-list-item class="workspaces-management--container"
          @click="$router.push({ name: selectedWorkspace.type + '.discovery.index' })">
          <div class="workspace-management-option"><span>{{ $t('main.header:discovery') }}</span></div>
        </v-list-item>
        <v-list-item class="workspaces-management--container"
          @click="$router.push({ name: selectedWorkspace.type + '.home.user.contents' })">
          <div class="workspace-management-option"><span>{{ $t('main.header:contents') }}</span></div>
        </v-list-item>
        <v-list-item v-if="allowedOnWorkspaceType(['business'])" class="workspaces-management--container"
          @click="$router.push({ name: selectedWorkspace.type + '.internal.positions' })">
          <div class="workspace-management-option"><span>{{ $t('main.header:internal.positions') }}</span></div>
        </v-list-item>
        <v-list-item v-if="allowedOnWorkspaceType(['individual', 'aggregator'])" class="workspaces-management--container"
          @click="$router.push({ name: selectedWorkspace.type + '.candidature.index' })">
          <div class="workspace-management-option"><span>{{ $t('main.header:candidacy') }}</span></div>
        </v-list-item>
      </template>
      <v-list-item v-if="isAdminCenter" class="workspaces-management--container"
        @click="$router.push({ name: selectedWorkspace.type + '.admin.center.index' })">
        <div class="workspace-management-option"><span>{{ $t('home.header.options:admin.center') }}</span></div>
      </v-list-item>
      <v-list-item v-if="activeProfile" class="workspaces-management--container"
        @click="$router.push({ name: 'settings.index' })">
        <div class="workspace-management-option"><span>{{ $t('home.header.options:profile') }}</span></div>
      </v-list-item>
    </template>
    <template v-if="userCanManage(selectedWorkspace.id) && selectedWorkspace.type !== 'individual' && isMobile">
      <div class="h-divider" />
      <h4 class="workspaces-management--menu_title">{{ $t('account.management.menu.section:title') }}</h4>
      <v-list-item v-for="(action, index) in actions" :key="index" class="workspaces-management--container"
        @click="centralActionHandler(action)">
        <div class="workspace-management-option"
          :class="{ 'active-workspace': (index === 0 && $route.name.indexOf('business.admin.center') != 0) || (index == 2 && $route.name.indexOf('business.admin.center') === 0) }">
          <span>{{ $t(`home.header.options:${action}`) }}</span>
          <v-icon
            v-if="(index === 0 && $route.name.indexOf('business.admin.center') != 0) || (index == 2 && $route.name.indexOf('business.admin.center') === 0)">mdi-check</v-icon>
        </div>
      </v-list-item>

    </template>
    <template v-if="userWorkspaces.length">
      <div class="h-divider"
        v-if="isMobile || (userCanManage(selectedWorkspace.id) && selectedWorkspace.type !== 'individual')" />
      <h4 class="workspaces-management--menu_title">{{ $t('workspace.management.menu.section:title') }}</h4>
      <template v-for="(workspace) in userWorkspaces">
        <v-list-item v-if="(selectedWorkspace.subdomain === workspace.subdomain || ((workspace.type === 'individual') && userWorkspaces.length <= 5)) || userWorkspaces.length <= 5 || (workspace.type === 'aggregator')" :key="workspace.id" class="workspaces-management--container"
          @click="!getUserInfo.isSupport && manageWorkspace(workspace)">
          <div class="workspace-management-option"
            :class="{ 'active-workspace': selectedWorkspace.subdomain === workspace.subdomain || (selectedWorkspace.type === 'individual' && selectedWorkspace.id === workspace.id) }">
            <v-img v-if="!!workspace.avatar && !(workspace.avatar.includes('/empty'))" height="36px" width="36px"
              :src="workspace.avatar ? changeThumbToSize(workspace.avatar, 'avatar') : defaultAvatarUrl('business')"></v-img>
            <v-avatar size="36" v-else><user-avatar-initials :user="workspace" :size="36" /></v-avatar>
            <span class="side-menu" :title="workspace.type !== 'individual' ? workspace.name : $t('workspace.type:individual')">{{
              workspace.type !== 'individual' ? workspace.name : $t('workspace.type:individual') }}</span>
            <v-icon v-if="selectedWorkspace.id === workspace.id">mdi-check</v-icon>
          </div>
        </v-list-item>
      </template>
      <template v-if="userWorkspaces.length > 5">
        <v-list-item class="workspaces-management--container" @click="$router.push({ name: 'workspace.selection.index' })">
          <div class="workspace-management-option">
            <v-icon color="#454545" size="36">mdi-view-module</v-icon>
            <span>{{$t('workspace.management.menu.section:replace')}} ({{ userWorkspaces.length - (userWorkspaces.find(wk => wk.type === 'aggregator') ? 2 : 1) }})</span>
          </div>
        </v-list-item>
      </template>
      <template v-for="(workspace) in userWorkspaces">
        <v-list-item v-if="(workspace.type === 'individual') && userWorkspaces.length > 5 && selectedWorkspace.type !== 'individual'" :key="workspace.id" class="workspaces-management--container"
          @click="!getUserInfo.isSupport && manageWorkspace(workspace)">
          <div class="workspace-management-option"
            :class="{ 'active-workspace': selectedWorkspace.subdomain === workspace.subdomain || (selectedWorkspace.type === 'individual' && selectedWorkspace.id === workspace.id) }">
            <v-img v-if="!!workspace.avatar && !(workspace.avatar.includes('/empty'))" height="36px" width="36px"
              :src="workspace.avatar ? changeThumbToSize(workspace.avatar, 'avatar') : defaultAvatarUrl('business')"></v-img>
            <v-avatar size="36" v-else><user-avatar-initials :user="workspace" :size="36" /></v-avatar>
            <span class="side-menu" :title="workspace.type !== 'individual' ? workspace.name : $t('workspace.type:individual')">{{
              workspace.type !== 'individual' ? workspace.name : $t('workspace.type:individual') }}</span>
            <v-icon v-if="selectedWorkspace.id === workspace.id">mdi-check</v-icon>
          </div>
        </v-list-item>
      </template>
    </template>
    <div class="h-divider mb-2 mt-2" />
    <v-list-item class="my-account-button" @click="!getUserInfo.isSupport && centralActionHandler('myAccount')">
      <div class="icon-wrapper my-account">
        <v-icon color="#454545" class="my-account-icon">mdi-cog</v-icon>
      </div>
      <span>{{ $t('home.header.options:profile') }}</span>
    </v-list-item>
    <v-list-item class="logout-button" @click="centralActionHandler('logout')">
      <div class="icon-wrapper logout">
        <v-icon color="#454545" class="logout-icon">mdi-logout</v-icon>
      </div>
      <span>{{ $t('home.header.options:logout') }}</span>
    </v-list-item>
</v-list>
</template>
<style lang="scss">
.v-list.avatar-menu--options {
  padding: 0;
  min-width: 300px;
  max-width: 100%;
  padding-bottom: 8px;

  .v-list-item.v-list-item--link {
    color: $neutral-dark !important;
    font-family: $lato;
  }

  .disabled {
    opacity: .3;
  }

  .h-divider {
    max-width: calc(100% - 30px);
    margin: 0 auto;
    opacity: .3;
  }

  .workspaces-areas--menu_title,
  .workspaces-management--menu_title {
    font-size: 16px;
    font-family: $lato;
    font-weight: bold;
    color: $neutral-medium;
    text-align: left;
    padding: 0 16px;
    height: 48px;
    line-height: 48px;
  }

  .workspaces-management--container {
    display: flex;
    cursor: pointer;
    padding: 0;

    &:before {
      background-color: currentColor;
      bottom: 0;
      content: "";
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      transition: .3s cubic-bezier(.25, .8, .5, 1);
    }

    &:hover {
      &:before {
        opacity: .04;
      }
    }

    .workspace-management-option {
      display: flex;
      align-items: center;
      color: $neutral-dark !important;
      font-family: $lato;
      padding: 8px 16px;
      height: 60px;

      .v-image {
        max-height: 36px;
        max-width: 36px;
        border-radius: 50%;

        .v-image__image {
          background-size: contain;
          max-height: 36px;
          max-width: 36px;
        }
      }

      &.active-workspace {
        background-color: #eee;
        width: 100%;
        border-radius: 4px;
        position: relative;

        .v-icon {
          position: absolute;
          right: 16px;
        }
      }

      span {
        margin-left: 8px;
      }

      side-menu {
        width: 180px;
        white-space: nowrap;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

  }

  .my-account-button,
  .logout-button {
    height: 40px;
    padding: 8px 16px 8px;

    .icon-wrapper.my-account,
    .icon-wrapper.logout {
      width: 36px;
    }

    span {
      margin-left: 8px;
    }
  }

  .avatar-menu--user-info {
    margin: 54px 16px 0;
    text-align: left;

    h5 {
      margin-top: 16px;
    }
  }

  @media only screen and (max-width: 800px) {
    padding: 0;

    .h-divider {
      background: #DBD5CE;
      max-width: calc(100% - 32px);
      margin: 25px auto;
    }

    .v-list-item {
      margin: 0 8px;
    }
  }
}
</style>
